import React from "react";
import { Link } from "gatsby";
import * as styles from "./styles.module.css";

interface Props {
  member: MemberType;
  href: string;
}

export default function TeamMemberTile({ member, href }: Props) {
  return (
    <Link
      to={href}
      className={styles.card}
      style={{ backgroundImage: `url(${member.photo?.image})` }}
    >
      <div
        className={`${styles.cardMemberInfo} px-2 pt-4 pb-3 xl:pl-7 xl:py-7 bg-white absolute left-0 bottom-0 w-full`}
      >
        <p className="leading-tight xl:text-xl text-left">{member.name}</p>
        <p className="text-tiny text-secondary font-semibold xl:text-base text-left">
          {member.position}
        </p>
      </div>
    </Link>
  );
}
